import { throttle } from '../lib/lodash.custom';
import { mql } from '../lib/utils';
import { primaryNav } from './primary-nav';
import { search } from './search';
import { tertiaryNav } from './tertiary-nav';

export const globalHeader = {

	$window: $(window),

	elem: {
		$globalHeader: $('#js-global-header')
	},

	lastScroll: 0,

	init() {
		// Modules
		primaryNav.init();
		search.init();
		tertiaryNav.init();

		this.bindUIActions();
		this.onResize(mql.large);
	},

	bindUIActions() {
		this.$window.on('scroll resize', throttle(this.onScroll.bind(this), 100));

		this.elem.$globalHeader
			.on('click', '#hamburger', this.menuToggle.bind(this))
			.on('mouseleave', '#hamburger', (e) => $(e.target).blur());

		mql.xlarge.addListener(this.onResize.bind(this));
	},

	menuToggle(e) {
		const $target = $(e.target);
		const $menu = this.elem.$globalHeader.find('.global-header__menu');

		$target
			.attr('aria-expanded', (i, txt) => (txt === 'false') ? 'true' : 'false')
			.attr('aria-label', (i, txt) => (txt.indexOf('Open') > -1) ? txt.replace('Open', 'Close') : txt.replace('Close', 'Open'))
			.toggleClass('hamburger--is-active');

		$menu.toggleClass('global-header__menu--is-active');

		$('html, body').toggleClass('body--no-scroll');
	},

	onResize(mq) {
		const $menu = this.elem.$globalHeader.find('.global-header__menu');
		const isActive = $menu.hasClass('global-header__menu--is-active');

		if (mq.matches && isActive) {
			$('html, body').removeClass('body--no-scroll');
		} else if (!mq.matches && isActive) {
			$('html, body').addClass('body--no-scroll');
		} else {
			$('html, body').removeClass('body--no-scroll');
		}
	},

	onScroll() {
		let currentScroll = window.pageYOffset || window.document.documentElement.scrollTop;
		let globalHeaderHeight = this.elem.$globalHeader.outerHeight(true) * 2;
		let topPosition = 0;

		if ($('.global-header--essential').length) {
			return;
		}

		if ($('.emergency-alert').length > 0) {
			$('.emergency-alert').each(function () {
				globalHeaderHeight += $(this).outerHeight(true);
				topPosition += $(this).outerHeight(true);
			});
		}

		if (currentScroll <= topPosition) {
			if (!this.elem.$globalHeader.hasClass('global-header--is-overlay')) {
				$('body').removeClass('masthead-is-scrolling');
			}

			this.elem.$globalHeader.removeClass('global-header--is-fixed global-header--is-visible');

			return;
		}

		if (mql.xlarge.matches) {
			if (currentScroll > this.lastScroll && currentScroll > globalHeaderHeight) {
				if (!this.elem.$globalHeader.hasClass('global-header--is-overlay')) {
					$('body').addClass('masthead-is-scrolling');
				}
				
				this.elem.$globalHeader.removeClass('global-header--is-visible');
				this.elem.$globalHeader.addClass('global-header--is-fixed');
			} else if (currentScroll < this.lastScroll && this.elem.$globalHeader.hasClass('global-header--is-fixed')) {
				this.elem.$globalHeader.addClass('global-header--is-visible');
			}
		} else {
			if (!this.elem.$globalHeader.hasClass('global-header--is-overlay')) {
				$('body').removeClass('masthead-is-scrolling');
			}

			this.elem.$globalHeader.removeClass('global-header--is-fixed global-header--is-visible');
		}
		if (currentScroll < (globalHeaderHeight * 3)) {
			this.elem.$globalHeader.removeClass('global-header--is-visible');
			$('body').removeClass('masthead-is-scrolling');
		}		
		if (currentScroll < (globalHeaderHeight * 2)) {
			this.elem.$globalHeader.removeClass('global-header--is-fixed');
		}		

		this.lastScroll = currentScroll;
	}

};
