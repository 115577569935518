// Lib
import { ready } from './lib/utils';
import { plugin } from './lib/plugin';
// Modules
import { alertTakeover } from './modules/alert-takeover';
import { alert } from './modules/alert';
import { cookie } from './modules/cookie';
import { globalHeader } from './modules/global-header';
import { Tooltip } from './modules/tooltip';

const main = {

	elem: {
		$textTooltip: $('.text-tooltip'),
		$tooltip: $('.tooltip')
	},

	init() {
		// Init plugin(s)
		plugin('Tooltip', Tooltip);

		// Modules
		alertTakeover.init();
		alert.init();
		cookie.init();
		globalHeader.init();

		this.bindUIActions();
	},

	bindUIActions() {
		// Plugin(s)
		this.elem.$tooltip.Tooltip({
			type: 'button',
			offset: 15
		});

		this.elem.$textTooltip.Tooltip({
			type: 'text',
			offset: 15,
			after(target) {
				$(target).toggleClass('text-tooltip--is-active');
			}
		});
	}

};

ready(function () {
	main.init();
});
