export const search = {

	elem: {
		$search: $('#site-search')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$search.on('click', '#site-search__toggle', this.onClick.bind(this));
		this.elem.$search.on('mouseleave', '#site-search__toggle', (e) => $(e.target).blur());
	},

	onClick(e) {
		e.preventDefault();

		const $target = $(e.target);

		$target.attr('aria-pressed', (i, txt) => (txt === 'false') ? 'true' : 'false');

		this.elem.$search.toggleClass('site-search--is-visible');

		// On transition end, redirect focus to the search input
		this.elem.$search.one('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', () => {
			const $input = this.elem.$search.find('.site-search__input');

			if (this.elem.$search.hasClass('site-search--is-visible')) {
				$input.attr('aria-hidden', 'false').attr('tabindex', '0').focus();
			} else {
				$input.attr('aria-hidden', 'true').attr('tabindex', '-1');
			}
		});
	}

};