const cookie = {
	// 2023-01-17 -- no longer setting/getting cookies through Javascript
	
	elem: {
		$accept: $('#btn-cookie-consent-accept, #btn-cookie-consent-close'),
		$popup: $('.cookie')
	},

	init() {
		// if (Cookies.get('cookie_consent_hide') !== undefined) {		
		//	cookie.elem.$popup.hide();
		// }
		
		this.elem.$accept.on('click', function(e) {
			e.preventDefault();			
			cookie.elem.$popup.addClass('cookie--selected');
			$.get('/_files/php/setcookie-consent.php');
			// Cookies.set('cookie_consent_hide', 'true', { expires: 14, Secure: true, HttpOnly: true });
			
			window.setTimeout(function() {
				cookie.elem.$popup.hide();
			},250);
		});
	}

};

export { cookie };

// to use:
//
// import cookie from './modules/cookie';
//
// cookie.init();
