export const alert = {

	elem: {
		$emergencyAlert: $('.emergency-alert').length > 0 ? $('.emergency-alert') : null
	},

	options: {
		duration: 425,
		easing: 'swing'
	},

	init() {
		if (this.elem.$emergencyAlert === null) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$emergencyAlert.on('click', '.emergency-alert__trigger', this.onClick.bind(this));
		this.elem.$emergencyAlert.on('mouseleave', '.emergency-alert__trigger', e => $(e.target).blur());
	},

	onClick(e) {
		e.preventDefault();

		const $parent = $(e.target).closest('.emergency-alert');
		const $content = $parent.find('.emergency-alert__content');

		// Current target
		$(e.target).attr('aria-expanded', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');
		$(e.target).attr('aria-pressed', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');

		// Parent
		$parent.toggleClass('emergency-alert--is-active');

		// Content
		$content.attr('aria-hidden', (i, currentValue) => (currentValue === 'true') ? 'false' : 'true');

		$content.slideToggle(this.options.duration, this.options.easing);
	}

};