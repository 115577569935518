export const alertTakeover = {
	// 2023-01-17 -- no longer setting/getting cookies through Javascript

	elem: {
		$alertTakeover: $('#js-alert-takeover').length > 0 ? $('#js-alert-takeover') : null
	},

	init() {
		if (this.elem.$alertTakeover === null) {
			return;
		}
		
		this.emergencyModTime = (emergencyModTime) ? String(emergencyModTime) : null;

		this.bindUIActions();
		this.displayAlert();
	},

	bindUIActions() {
		this.elem.$alertTakeover.on('click', '.btn--medium', this.onClick.bind(this));
		
		$(document).on('keydown', this.onKeyDown.bind(this));
	},

	displayAlert() {
		//	if (Cookies.get('alert') === undefined || Cookies.get('emergencyModTime') === undefined) {
		//		Cookies.set('emergencyModTime', this.emergencyModTime, { Secure: true, HttpOnly: true });
		//		$('html, body').addClass('body--no-scroll');
		//		this.elem.$alertTakeover.attr('aria-modal', 'true');			
		//	} else if (Cookies.get('emergencyModTime') !== undefined && Cookies.get('emergencyModTime') !== this.emergencyModTime) {
		//		Cookies.remove('alert');
		//		Cookies.remove('emergencyModTime');
		//		Cookies.set('emergencyModTime', this.emergencyModTime, { Secure: true, HttpOnly: true });
		//		$('html, body').addClass('body--no-scroll');
		//		this.elem.$alertTakeover.attr('aria-modal', 'true');
		//	}

		$('html, body').addClass('body--no-scroll');
		this.elem.$alertTakeover.attr('aria-modal', 'true');
	},

	onClick(e) {
		e.preventDefault();

		let time;

		// Set time
		time = new Date();
		time.setTime(time.getTime() + 60 * 60 * 1000);

		// Set cookie
		// Cookies.set('alert', 'takeover', { expires: time, Secure: true, HttpOnly: true });
		$.get('/_files/php/setcookie-takeover.php');

		$('html, body').removeClass('body--no-scroll');

		// Hide alert
		this.elem.$alertTakeover.attr('aria-modal', 'false');
	},

	onKeyDown(e) {
		if (e.keyCode === 27) { // ESC
			$('.btn--medium', this.elem.$alertTakeover).trigger('click');
		}
	}

};
