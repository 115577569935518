import { mql } from '../lib/utils';

export const tertiaryNav = {

	$window: $(window),

	duration: 425,

	elem: {
		$globalHeader: $('#js-global-header')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		$(document).on('click touchstart', this.onClick.bind(this));

		this.$window.on('keydown', this.onKeyDown.bind(this));
		this.$window.on('keyup', this.onKeyUp.bind(this));

		this.elem.$globalHeader.on('click', '.tertiary-nav__parent-link', this.toggleChildNav.bind(this));
	},

	onClick(e) {
		const _this = this;
		const $target = $(e.target);

		if (!$target.is('[class*=\'tertiary-nav\']')) {
			$('.tertiary-nav__item').each(function () {
				let cls = 'tertiary-nav__item--is-active';
				let $link = $(this).find('.tertiary-nav__parent-link');

				if ($(this).hasClass(cls)) {
					$(this).removeClass(cls);
					if ($link.attr('aria-expanded') && $link.attr('aria-expanded', 'true')) {
						_this.setExpandedState($link);
					}
				}
			});
		}
	},

	onKeyDown(e) {
		const code = (e.keyCode ? e.keyCode : e.which);
		const isTertiaryNav = $(document.activeElement).closest('.tertiary-nav').length > 0;

		let item;
		let i;

		if (mql.xlarge.matches) {
			if (isTertiaryNav) {
				if (code === 38 || code === 40) {
					let hasChildList = $(document.activeElement).parent().find('.tertiary-nav__child-list').length > 0;

					if (hasChildList) {
						e.preventDefault(); // Prevent the page from scrolling up or down

						let $childList = $(document.activeElement).parent().find('.tertiary-nav__child-list');

						$(document.activeElement).parent().addClass('tertiary-nav__item--is-active');

						$childList
							.find('.tertiary-nav__child-item').eq(0)
							.find('.tertiary-nav__child-link')
							.focus();
					} else {
						e.preventDefault();

						// Up arrow (38)
						if (code === 38) {
							item = $(document.activeElement).parent().prev();
							// Down arrow (40)
						} else if (code === 40) {
							item = $(document.activeElement).parent().next();
						}

						if (item.length > 0) {
							// Return to the first index or element within the list
							if (item.index() === ($('.tertiary-nav__child-item').length)) {
								item = $('.tertiary-nav__child-item').eq(0);
							}

							item.find('.tertiary-nav__child-link').focus();
						} else {
							i = (item.index() === -1 && code === 38) ? ($(document.activeElement).parent().siblings().length) : 0;

							item = $(document.activeElement).parent().parent().find('.tertiary-nav__child-item').eq(i);
							item.find('.tertiary-nav__child-link').focus();
						}
					}
				}
			}
		}

	},

	onKeyUp(e) {
		const code = (e.keyCode ? e.keyCode : e.which);
		const isTertiaryNav = $(document.activeElement).closest('.tertiary-nav').length > 0;

		if (mql.xlarge.matches) {
			if (code === 9) {
				if (isTertiaryNav) {
					$(document.activeElement).closest('.tertiary-nav__item').addClass('tertiary-nav__item--is-active');

					this.setExpandedState($(document.activeElement));
				} else {
					let $item = $('.tertiary-nav__item');
					let $link = $item.find('.tertiary-nav__parent-link');
					
					$item.removeClass('tertiary-nav__item--is-active');

					if ($link.attr('aria-expanded') && $link.attr('aria-expanded', 'true')) {
						this.setExpandedState($link);
					}
				}
			}
		}
	},

	toggleChildNav(e) {
		e.preventDefault();
		e.stopImmediatePropagation();

		if (mql.xlarge.matches) {
			return;
		}

		const $target = $(e.target);

		$target.parent('.tertiary-nav__item').toggleClass('tertiary-nav__item--is-active');
		$target.attr('aria-expanded', (i, txt) => (txt === 'false') ? 'true' : 'false');

		if (!mql.xlarge.matches) {
			$target.parent('.tertiary-nav__item').find('.tertiary-nav__child-list').slideToggle(this.duration, 'swing');
		}
	},

	setExpandedState(e) {
		let element = (typeof e.target !== typeof undefined) ? $(e.target) : e;
		let expanded = element.attr('aria-expanded');

		if (typeof expanded !== typeof undefined && expanded !== false) {
			element.attr('aria-expanded', (i, val) => (val === 'true') ? 'false' : 'true');
		}
	}

};